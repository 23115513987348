#section-2-143 > .ct-section-inner-wrap {
  max-width: 1920px;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center
}

#section-2-143 {
  display: block
}

#section-2-143 {
  background-image: linear-gradient(#212121,#000000);
  text-align: left;
  background-attachment: fixed;
  overflow: hidden
}

#div_block-4-143 {
  flex-direction: column;
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ebebed;
  overflow: hidden
}

#up {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%
}

#div_block-3-143 {
  text-align: left;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  z-index: 30;
  top: 0px;
  padding-top: 0.6%;
  padding-bottom: 0.6%;
  display: flex;
  position: fixed;
  box-shadow: 0 8px 32px #0000001a;
  backdrop-filter: blur(5px)
}

#div_block-11-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  width: 95%
}

#div_block-492-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 70%
}

#div_block-40-165 {
  display: none
}

#div_block-474-165 {
  text-align: left;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #160c0a;
  z-index: 10;
  top: 0px;
  padding-top: 0.6%;
  padding-bottom: 0.6%;
  display: flex;
  opacity: 1;
  box-shadow: 0 8px 32px #0000001a;
  backdrop-filter: blur(5px)
}

#div_block-475-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  opacity: 0
}

#div_block-42-165 {
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 75%;
  margin-top: 3%;
  margin-bottom: 3%
}

#div_block-404-165 {
  text-align: right;
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-bottom: 2.5%
}

#div_block-48-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%
}

#div_block-339-165 {
  display: none;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 62%;
  margin-top: 3%;
  margin-bottom: 3%
}

#div_block-340-165 {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 6%
}

#div_block-342-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%
}

#div_block-343-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  background-color: #ffffff;
  border-radius: 10px;
  padding-bottom: 2%
}

#div_block-344-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 70%
}

#div_block-345-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin-top: -15%;
  margin-bottom: 4%
}

#div_block-347-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: dashed;
  border-left-style: none;
  border-bottom-color: #d9d9d9;
  border-bottom-width: 3px;
  margin-bottom: 5%;
  padding-bottom: 3%
}

#div_block-350-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
  width: 100%
}

#div_block-351-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%
}

#div_block-352-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 10%;
  margin-right: 2%
}

#div_block-355-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 4%;
  margin-bottom: 4%
}

#div_block-356-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 10%;
  margin-right: 2%
}

#div_block-359-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%
}

#div_block-360-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 10%;
  margin-right: 2%
}

#div_block-363-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  background-color: #ffffff;
  border-radius: 10px;
  padding-bottom: 2%;
  margin-right: 2%;
  margin-left: 2%
}

#div_block-364-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 70%
}

#div_block-365-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin-top: -15%;
  margin-bottom: 4%
}

#div_block-367-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: dashed;
  border-left-style: none;
  border-bottom-color: #d9d9d9;
  border-bottom-width: 3px;
  margin-bottom: 5%;
  padding-bottom: 3%
}

#div_block-370-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
  width: 100%
}

#div_block-371-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%
}

#div_block-372-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 10%;
  margin-right: 2%
}

#div_block-375-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 4%;
  margin-bottom: 4%
}

#div_block-376-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 10%;
  margin-right: 2%
}

#div_block-379-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%
}

#div_block-380-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 10%;
  margin-right: 2%
}

#div_block-383-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  background-color: #ffffff;
  border-radius: 10px;
  padding-bottom: 2%
}

#div_block-384-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 70%
}

#div_block-385-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin-top: -15%;
  margin-bottom: 4%
}

#div_block-387-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: dashed;
  border-left-style: none;
  border-bottom-color: #d9d9d9;
  border-bottom-width: 3px;
  margin-bottom: 5%;
  padding-bottom: 3%
}

#div_block-390-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
  width: 100%
}

#div_block-391-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 0px;
  padding-top: 0px
}

#div_block-392-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 10%;
  margin-right: 2%
}

#div_block-395-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 4%;
  margin-bottom: 4%
}

#div_block-396-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 10%;
  margin-right: 2%
}

#div_block-399-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%
}

#div_block-400-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 10%;
  margin-right: 2%
}

#div_block-5-143 {
  flex-direction: column;
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #000000;
  padding-top: 2%;
  padding-bottom: 3%
}

#div_block-149-165 {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 68%
}

#div_block-153-165 {
  width: 26%;
  align-items: flex-start;
  text-align: left
}

#div_block-154-165 {
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  width: 76%;
  flex-direction: row
}

#div_block-155-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 33%
}

#div_block-156-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 4%
}

#div_block-160-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-510-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-512-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-514-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-516-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-518-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-174-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 33%;
  margin-right: 3%;
  margin-left: 3%
}

#div_block-175-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 4%
}

#div_block-522-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-525-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-528-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-531-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-534-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-537-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

#div_block-190-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 34%;
  background-color: #000000
}

#div_block-550-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 4%
}

#div_block-553-165 {
  display: none;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
  width: 68%;
  margin-top: 1%;
  border-top-style: solid;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: #333333;
  border-top-width: 1px;
  padding-top: 1.5%
}

#div_block-559-165 {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0%;
  margin-bottom: 1%
}

#div_block-213-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  background-color: #181818;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 1%
}

#div_block-447-165 {
  width: 100%;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center
}

@media (max-width: 1120px) {
  #div_block-42-165 {
      width:85%
  }
}

@media (max-width: 1120px) {
  #div_block-339-165 {
      width:72%
  }
}

@media (max-width: 1120px) {
  #div_block-149-165 {
      width:78%
  }
}

@media (max-width: 1120px) {
  #div_block-553-165 {
      width:78%
  }
}

@media (max-width: 991px) {
  #div_block-492-165 {
      width:89%
  }
}

@media (max-width: 991px) {
  #div_block-42-165 {
      width:90%
  }
}

@media (max-width: 991px) {
  #div_block-339-165 {
      width:82%
  }
}

@media (max-width: 991px) {
  #div_block-149-165 {
      width:88%
  }
}

@media (max-width: 991px) {
  #div_block-553-165 {
      width:88%
  }
}

@media (max-width: 767px) {
  #div_block-492-165 {
      width:85%
  }
}

@media (max-width: 479px) {
  #div_block-3-143 {
      padding-top:2%;
      padding-bottom: 2%
  }
}

@media (max-width: 479px) {
  #div_block-11-165 {
      position:relative;
      text-align: left;
      align-items: center;
      justify-content: center
  }
}

@media (max-width: 479px) {
  #div_block-492-165 {
      display:none
  }
}

@media (max-width: 479px) {
  #div_block-40-165 {
      flex-direction:column;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: flex-start;
      width: 14%;
      position: absolute;
      left: 0px
  }
}

@media (max-width: 479px) {
  #div_block-474-165 {
      padding-top:2%;
      padding-bottom: 2%
  }
}

@media (max-width: 479px) {
  #div_block-475-165 {
      position:relative;
      text-align: left;
      align-items: center;
      justify-content: center
  }
}

@media (max-width: 479px) {
  #div_block-42-165 {
      width:93%;
      margin-top: 5%;
      margin-bottom: 6%
  }
}

@media (max-width: 479px) {
  #div_block-404-165 {
      margin-bottom:3.5%
  }
}

@media (max-width: 479px) {
  #div_block-48-165 {
      text-align:center;
      flex-direction: column;
      display: flex
  }
}

@media (max-width: 479px) {
  #div_block-339-165 {
      width:93%;
      margin-top: 6%;
      margin-bottom: 10%
  }
}

@media (max-width: 479px) {
  #div_block-340-165 {
      margin-bottom:16%
  }
}

@media (max-width: 479px) {
  #div_block-342-165 {
      text-align:center;
      flex-direction: column;
      display: flex
  }
}

@media (max-width: 479px) {
  #div_block-343-165 {
      width:100%;
      padding-bottom: 6%
  }
}

@media (max-width: 479px) {
  #div_block-344-165 {
      width:80%
  }
}

@media (max-width: 479px) {
  #div_block-352-165 {
      width:8%
  }
}

@media (max-width: 479px) {
  #div_block-356-165 {
      width:8%
  }
}

@media (max-width: 479px) {
  #div_block-360-165 {
      width:8%
  }
}

@media (max-width: 479px) {
  #div_block-363-165 {
      width:100%;
      padding-bottom: 6%;
      margin-right: 0%;
      margin-left: 0%;
      margin-top: 18%;
      margin-bottom: 18%
  }
}

@media (max-width: 479px) {
  #div_block-364-165 {
      width:80%
  }
}

@media (max-width: 479px) {
  #div_block-372-165 {
      width:8%
  }
}

@media (max-width: 479px) {
  #div_block-376-165 {
      width:8%
  }
}

@media (max-width: 479px) {
  #div_block-380-165 {
      width:8%
  }
}

@media (max-width: 479px) {
  #div_block-383-165 {
      width:100%;
      padding-bottom: 6%
  }
}

@media (max-width: 479px) {
  #div_block-384-165 {
      width:80%
  }
}

@media (max-width: 479px) {
  #div_block-392-165 {
      width:8%
  }
}

@media (max-width: 479px) {
  #div_block-396-165 {
      width:8%
  }
}

@media (max-width: 479px) {
  #div_block-400-165 {
      width:8%
  }
}

@media (max-width: 479px) {
  #div_block-5-143 {
      padding-top:5%;
      padding-bottom: 4%
  }
}

@media (max-width: 479px) {
  #div_block-149-165 {
      width:93%;
      text-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      display: flex;
      margin-bottom: 3%
  }
}

@media (max-width: 479px) {
  #div_block-153-165 {
      width:65%;
      margin-bottom: 4%
  }
}

@media (max-width: 479px) {
  #div_block-154-165 {
      width:90%;
      text-align: left;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center
  }
}

@media (max-width: 479px) {
  #div_block-155-165 {
      width:100%;
      text-align: left;
      align-items: center;
      justify-content: center
  }
}

@media (max-width: 479px) {
  #div_block-156-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-160-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-510-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-512-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-514-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-516-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-518-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-174-165 {
      width:100%;
      justify-content: center;
      text-align: left;
      align-items: center;
      margin-top: 3%;
      margin-bottom: 3%;
      border-top-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 1px;
      border-left-width: 1px;
      border-top-style: solid;
      border-right-style: none;
      border-bottom-style: solid;
      border-left-style: none;
      border-top-color: rgba(255,255,255,0.41);
      border-right-color: rgba(255,255,255,0.41);
      border-bottom-color: rgba(255,255,255,0.41);
      border-left-color: rgba(255,255,255,0.41);
      padding-top: 3%
  }
}

@media (max-width: 479px) {
  #div_block-175-165 {
      text-align:left;
      align-items: center;
      justify-content: center
  }
}

@media (max-width: 479px) {
  #div_block-522-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-525-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-528-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-531-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-534-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-537-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-190-165 {
      width:73%;
      text-align: left;
      align-items: flex-start
  }
}

@media (max-width: 479px) {
  #div_block-550-165 {
      text-align:left;
      align-items: center;
      justify-content: center
  }
}

@media (max-width: 479px) {
  #div_block-553-165 {
      width:93%;
      padding-top: 3.5%
  }
}

@media (max-width: 479px) {
  #div_block-559-165 {
      margin-bottom:3%
  }
}

@media (max-width: 479px) {
  #div_block-213-165 {
      padding-top:3%;
      padding-bottom: 3%
  }
}

@media (max-width: 479px) {
  #div_block-419-165 {
      flex-direction:column;
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: flex-start;
      width: 90%;
      height: 100%;
      background-color: #ffffff;
      overflow: scroll
  }
}

@media (max-width: 479px) {
  #div_block-420-165 {
      flex-direction:row;
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-right: 0%;
      padding-top: 2%;
      padding-bottom: 2%
  }
}

@media (max-width: 479px) {
  #div_block-421-165 {
      width:8.5%;
      margin-bottom: -2px;
      margin-top: 2px;
      margin-left: 3%
  }
}

@media (max-width: 479px) {
  #div_block-430-165 {
      flex-direction:column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      margin-top: 4%;
      padding-bottom: 0px
  }
}

@media (max-width: 479px) {
  #link-434-165 {
      flex-direction:column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      width: 100%
  }
}

@media (max-width: 479px) {
  #div_block-459-165 {
      width:100%;
      padding-top: 2%;
      padding-right: 0%;
      padding-bottom: 2%;
      padding-left: 0%
  }
}

#text_block-405-165 {
  color: #ff7e00;
  font-size: clamp(1.25rem,calc(1.25rem + ((1vw - 0.3rem) * 0.6944)),1.875rem) ;
  font-weight: 700;
  line-height: 1
}

#text_block-348-165 {
  font-weight: 500;
  color: #000000;
  font-size: clamp(0.875rem,calc(0.875rem + ((1vw - 0.3rem) * 0.5556)),1.375rem)
}

#text_block-349-165 {
  font-weight: 300;
  color: #000000;
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.4167)),1.125rem)
}

#text_block-354-165 {
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem) ;
  font-weight: 300
}

#text_block-358-165 {
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem) ;
  font-weight: 300
}

#text_block-362-165 {
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem) ;
  font-weight: 300
}

#text_block-368-165 {
  font-weight: 500;
  color: #000000;
  font-size: clamp(0.875rem,calc(0.875rem + ((1vw - 0.3rem) * 0.5556)),1.375rem)
}

#text_block-369-165 {
  font-weight: 300;
  color: #000000;
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.4167)),1.125rem)
}

#text_block-374-165 {
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem) ;
  font-weight: 300
}

#text_block-378-165 {
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem) ;
  font-weight: 300
}

#text_block-382-165 {
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem) ;
  font-weight: 300
}

#text_block-388-165 {
  font-weight: 500;
  color: #000000;
  font-size: clamp(0.875rem,calc(0.875rem + ((1vw - 0.3rem) * 0.5556)),1.375rem)
}

#text_block-389-165 {
  font-weight: 300;
  color: #000000;
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.4167)),1.125rem)
}

#text_block-394-165 {
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem) ;
  font-weight: 300
}

#text_block-398-165 {
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem) ;
  font-weight: 300
}

#text_block-402-165 {
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem) ;
  font-weight: 300
}

#text_block-157-165 {
  color: #fe7e00;
  font-size: clamp(0.8125rem,calc(0.8125rem + ((1vw - 0.3rem) * 0.625)),1.375rem) ;
  font-weight: 500
}

#text_block-176-165 {
  color: #fe7e00;
  font-size: clamp(0.8125rem,calc(0.8125rem + ((1vw - 0.3rem) * 0.625)),1.375rem) ;
  font-weight: 500
}

#text_block-551-165 {
  color: #fe7e00;
  font-size: clamp(0.8125rem,calc(0.8125rem + ((1vw - 0.3rem) * 0.625)),1.375rem) ;
  font-weight: 500
}

#text_block-541-165 {
  color: #ffffff
}

#text_block-558-165 {
  color: #fe7e00;
  font-size: clamp(0.8125rem,calc(0.8125rem + ((1vw - 0.3rem) * 0.3472)),1.125rem) ;
  margin-bottom: 1%
}

#text_block-220-165 {
  color: #ffffff;
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.4167)),1.125rem) ;
  font-weight: 300
}

@media (max-width: 1120px) {
  #text_block-354-165 {
      font-size:clamp(0.625rem,calc(0.625rem + ((1vw - 0.3rem) * 0.2778)),0.875rem)
  }
}

@media (max-width: 1120px) {
  #text_block-358-165 {
      font-size:clamp(0.625rem,calc(0.625rem + ((1vw - 0.3rem) * 0.2778)),0.875rem)
  }
}

@media (max-width: 1120px) {
  #text_block-362-165 {
      font-size:clamp(0.625rem,calc(0.625rem + ((1vw - 0.3rem) * 0.2778)),0.875rem)
  }
}

@media (max-width: 1120px) {
  #text_block-374-165 {
      font-size:clamp(0.625rem,calc(0.625rem + ((1vw - 0.3rem) * 0.2778)),0.875rem)
  }
}

@media (max-width: 1120px) {
  #text_block-378-165 {
      font-size:clamp(0.625rem,calc(0.625rem + ((1vw - 0.3rem) * 0.2778)),0.875rem)
  }
}

@media (max-width: 1120px) {
  #text_block-382-165 {
      font-size:clamp(0.625rem,calc(0.625rem + ((1vw - 0.3rem) * 0.2778)),0.875rem)
  }
}

@media (max-width: 1120px) {
  #text_block-394-165 {
      font-size:clamp(0.625rem,calc(0.625rem + ((1vw - 0.3rem) * 0.2778)),0.875rem)
  }
}

@media (max-width: 1120px) {
  #text_block-398-165 {
      font-size:clamp(0.625rem,calc(0.625rem + ((1vw - 0.3rem) * 0.2778)),0.875rem)
  }
}

@media (max-width: 1120px) {
  #text_block-402-165 {
      font-size:clamp(0.625rem,calc(0.625rem + ((1vw - 0.3rem) * 0.2778)),0.875rem)
  }
}

@media (max-width: 479px) {
  #text_block-354-165 {
      font-size:clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem)
  }
}

@media (max-width: 479px) {
  #text_block-358-165 {
      font-size:clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem)
  }
}

@media (max-width: 479px) {
  #text_block-362-165 {
      font-size:clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem)
  }
}

@media (max-width: 479px) {
  #text_block-374-165 {
      font-size:clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem)
  }
}

@media (max-width: 479px) {
  #text_block-378-165 {
      font-size:clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem)
  }
}

@media (max-width: 479px) {
  #text_block-382-165 {
      font-size:clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem)
  }
}

@media (max-width: 479px) {
  #text_block-394-165 {
      font-size:clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem)
  }
}

@media (max-width: 479px) {
  #text_block-398-165 {
      font-size:clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem)
  }
}

@media (max-width: 479px) {
  #text_block-402-165 {
      font-size:clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem)
  }
}

@media (max-width: 479px) {
  #text_block-157-165 {
      font-size:20px
  }
}

@media (max-width: 479px) {
  #text_block-176-165 {
      font-size:20px
  }
}

@media (max-width: 479px) {
  #text_block-551-165 {
      font-size:20px
  }
}

@media (max-width: 479px) {
  #text_block-432-165 {
      font-size:16px;
      font-weight: 400
  }
}

@media (max-width: 479px) {
  #text_block-458-165 {
      padding-left:8%;
      font-size: 16px
  }
}

@media (max-width: 479px) {
  #text_block-461-165 {
      padding-left:14%;
      background-color: rgba(0,0,0,0);
      font-size: 14px
  }
}

@media (max-width: 479px) {
  #text_block-469-165 {
      padding-left:14%;
      background-color: rgba(0,0,0,0);
      font-size: 14px
  }
}

@media (max-width: 479px) {
  #text_block-467-165 {
      padding-left:14%;
      background-color: rgba(0,0,0,0);
      font-size: 14px
  }
}

@media (max-width: 479px) {
  #text_block-465-165 {
      padding-left:14%;
      background-color: rgba(0,0,0,0);
      font-size: 14px
  }
}

@media (max-width: 479px) {
  #text_block-483-165 {
      padding-left:14%;
      background-color: rgba(0,0,0,0);
      font-size: 14px
  }
}

@media (max-width: 479px) {
  #text_block-463-165 {
      padding-left:14%;
      background-color: rgba(0,0,0,0);
      font-size: 14px
  }
}

@media (max-width: 479px) {
  #text_block-471-165 {
      padding-left:8%;
      font-size: 16px
  }
}

@media (max-width: 479px) {
  #text_block-473-165 {
      padding-left:8%;
      font-size: 16px
  }
}

@media (max-width: 479px) {
  #text_block-444-165 {
      font-size:16px
  }
}

@media (max-width: 479px) {
  #text_block-507-165 {
      font-size:16px
  }
}

@media (max-width: 479px) {
  #text_block-442-165 {
      font-size:16px
  }
}

@media (max-width: 479px) {
  #text_block-438-165 {
      font-size:16px
  }
}

@media (max-width: 479px) {
  #text_block-440-165 {
      font-size:16px
  }
}

@media (max-width: 479px) {
  #text_block-426-165 {
      color:0;
      font-size: 0px
  }

  #text_block-426-165:hover {
      color: 0
  }
}


#div_block-12-165:hover {
  filter: saturate(160%)
}


#link-476-165:hover {
  filter: saturate(160%)
}

#div_block-150-165 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 90%
}

#div_block-540-165 {
  flex-direction: column;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3%
}

@media (max-width: 479px) {
  #div_block-12-165 {
      width:32%
  }
}

@media (max-width: 479px) {
  #link-476-165 {
      width:32%
  }
}

@media (max-width: 479px) {
  #div_block-150-165 {
      width:100%
  }
}

@media (max-width: 479px) {
  #div_block-540-165 {
      text-align:left;
      align-items: center
  }
}

@media (max-width: 479px) {
  #div_block-423-165 {
      width:32%;
      margin-right: 4%
  }
}

@media (max-width: 479px) {
  #div_block-431-165 {
      flex-direction:column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      padding-top: 2%;
      padding-right: 3%;
      padding-bottom: 2%;
      padding-left: 4%;
      color: #000000
  }

  #div_block-431-165:hover {
      color: #ff7e00;
      background-color: #feefe8
  }
}

@media (max-width: 479px) {
  #div_block-460-165 {
      width:100%;
      text-align: left;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      display: flex;
      padding-top: 2%;
      padding-right: 0%;
      padding-bottom: 2%;
      padding-left: 0%;
      color: #000000
  }

  #div_block-460-165:hover {
      color: #ff7e00;
      background-color: #feefe8
  }
}

@media (max-width: 479px) {
  #div_block-468-165 {
      width:100%;
      flex-direction: column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      padding-top: 2%;
      padding-right: 0%;
      padding-bottom: 2%;
      padding-left: 0%;
      color: #000000
  }

  #div_block-468-165:hover {
      color: #ff7e00;
      background-color: #feefe8
  }
}

@media (max-width: 479px) {
  #div_block-466-165 {
      width:100%;
      text-align: left;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      display: flex;
      padding-top: 2%;
      padding-bottom: 2%;
      color: #000000;
      margin-top: 0px
  }

  #div_block-466-165:hover {
      color: #ff7e00;
      background-color: #feefe8
  }
}

@media (max-width: 479px) {
  #div_block-464-165 {
      width:100%;
      text-align: left;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      display: flex;
      padding-top: 2%;
      padding-bottom: 2%;
      color: #000000
  }

  #div_block-464-165:hover {
      color: #ff7e00;
      width: #feefe8px;
      background-color: #feefe8
  }
}

@media (max-width: 479px) {
  #div_block-482-165 {
      width:100%;
      justify-content: center;
      text-align: left;
      align-items: flex-start;
      flex-direction: column;
      display: flex;
      padding-top: 2%;
      padding-bottom: 2%;
      color: #000000
  }

  #div_block-482-165:hover {
      color: #ff7e00;
      background-color: #feefe8
  }
}

@media (max-width: 479px) {
  #div_block-462-165 {
      width:100%;
      flex-direction: column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      padding-top: 2%;
      padding-bottom: 2%;
      color: #000000
  }

  #div_block-462-165:hover {
      color: #ff7e00;
      background-color: #feefe8
  }
}

@media (max-width: 479px) {
  #div_block-470-165 {
      width:100%;
      flex-direction: column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      padding-top: 2%;
      padding-bottom: 2%;
      color: #000000
  }

  #div_block-470-165:hover {
      background-color: #feefe8;
      color: #ff7e00
  }
}

@media (max-width: 479px) {
  #div_block-472-165 {
      width:100%;
      flex-direction: column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      padding-top: 2%;
      padding-bottom: 2%;
      color: #000000
  }

  #div_block-472-165:hover {
      background-color: #feefe8;
      color: #ff7e00
  }
}

@media (max-width: 479px) {
  #div_block-443-165 {
      flex-direction:column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      padding-top: 2%;
      padding-right: 3%;
      padding-bottom: 2%;
      padding-left: 4%;
      color: #000000
  }

  #div_block-443-165:hover {
      background-color: #feefe8;
      color: #ff7e00
  }
}

@media (max-width: 479px) {
  #link-506-165 {
      flex-direction:column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      padding-top: 2%;
      padding-right: 3%;
      padding-bottom: 2%;
      padding-left: 4%;
      color: #000000
  }

  #link-506-165:hover {
      background-color: #feefe8;
      color: #ff7e00
  }
}

@media (max-width: 479px) {
  #div_block-441-165 {
      flex-direction:column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      padding-top: 2%;
      padding-right: 3%;
      padding-bottom: 2%;
      padding-left: 4%;
      color: #000000;
      margin-bottom: 0px
  }

  #div_block-441-165:hover {
      background-color: #feefe8;
      color: #ff7e00
  }
}

@media (max-width: 479px) {
  #div_block-437-165 {
      flex-direction:column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      padding-right: 3%;
      padding-top: 2%;
      padding-bottom: 2%;
      padding-left: 4%;
      color: #000000
  }

  #div_block-437-165:hover {
      background-color: #feefe8;
      color: #ff7e00
  }
}

@media (max-width: 479px) {
  #div_block-439-165 {
      flex-direction:column;
      display: flex;
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      padding-right: 3%;
      padding-top: 2%;
      padding-bottom: 2%;
      padding-left: 4%;
      color: #000000
  }

  #div_block-439-165:hover {
      background-color: #feefe8;
      color: #ff7e00
  }
}

#image-14-165 {
  width: 100%;
  height: 100%;
  opacity: 0
}

#image-477-165 {
  width: 100%;
  height: 100%;
  opacity: 0
}

#image-346-165 {
  width: 100%;
  height: 100%
}

#image-353-165 {
  width: 100%;
  height: 100%
}

#image-357-165 {
  width: 100%;
  height: 100%
}

#image-361-165 {
  width: 100%;
  height: 100%
}

#image-366-165 {
  width: 100%;
  height: 100%
}

#image-373-165 {
  width: 100%;
  height: 100%
}

#image-377-165 {
  width: 100%;
  height: 100%
}

#image-381-165 {
  width: 100%;
  height: 100%
}

#image-386-165 {
  width: 100%;
  height: 100%
}

#image-393-165 {
  width: 100%;
  height: 100%
}

#image-397-165 {
  width: 100%;
  height: 100%
}

#image-401-165 {
  width: 100%;
  height: 100%
}

#image-151-165 {
  width: 100%;
  height: 100%;
  transition-duration: 0.4s
}

#image-151-165:hover {
  filter: saturate(150%)
}

#image-422-165 {
  width: 100%;
  height: 100%
}

@media (max-width: 479px) {
  #image-422-165 {
      filter:brightness(500%);
      transition-duration: 0.4s
  }

  #image-422-165:hover {
      filter: brightness(300%)
  }
}

@media (max-width: 479px) {
  #image-424-165 {
      width:100%;
      height: 100%;
      transition-duration: 0.4s
  }

  #image-424-165:hover {
      filter: saturate(200%)
  }
}

@media (max-width: 479px) {
  #fancy_icon-41-165>svg {
      width:30px;
      height: 30px
  }
}

#code_block-560-165 {
  font-size: clamp(0.6rem,calc(0.6rem + ((1vw - 0.3rem) * 0.5556)),1.1rem) ;
  font-weight: 400;
  color: #ffffff;
  padding-top: 0px;
  text-align: center
}

@media (max-width: 479px) {
  #code_block-560-165 {
      text-align:center;
      font-size: 12px;
      margin-top: 0px
  }
}

#_nav_menu-505-165 .oxy-nav-menu-hamburger-line {
  background-color: #000000
}

#_nav_menu-505-165.oxy-nav-menu.oxy-nav-menu-open {
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important
}

#_nav_menu-505-165.oxy-nav-menu.oxy-nav-menu-open .menu-item a {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 30px
}

#_nav_menu-505-165 .oxy-nav-menu-hamburger-wrap {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px
}

#_nav_menu-505-165 .oxy-nav-menu-hamburger {
  width: 40px;
  height: 32px
}

#_nav_menu-505-165 .oxy-nav-menu-hamburger-line {
  height: 6px
}

#_nav_menu-505-165.oxy-nav-menu-open .oxy-nav-menu-hamburger .oxy-nav-menu-hamburger-line:first-child {
  top: 13px
}

#_nav_menu-505-165.oxy-nav-menu-open .oxy-nav-menu-hamburger .oxy-nav-menu-hamburger-line:last-child {
  top: -13px
}

#_nav_menu-505-165 {
  width: 100%
}

#_nav_menu-505-165 .oxy-nav-menu-list {
  flex-direction: row
}

#_nav_menu-505-165 .menu-item a {
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  font-size: clamp(0.75rem,calc(0.75rem + ((1vw - 0.3rem) * 0.2778)),1rem) ;
  color: #000000;
  font-weight: 400;
  padding-right: 30px
}

#_nav_menu-505-165 .current-menu-item a {
  color: #ff7e00
}

#_nav_menu-505-165.oxy-nav-menu:not(.oxy-nav-menu-open) .sub-menu {
  background-color: #ffffff
}

#_nav_menu-505-165.oxy-nav-menu:not(.oxy-nav-menu-open) .sub-menu .menu-item a {
  border: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #000000;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px
}

#_nav_menu-505-165.oxy-nav-menu:not(.oxy-nav-menu-open) .oxy-nav-menu-list .sub-menu .menu-item a:hover {
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px
}

#_nav_menu-505-165 .menu-item:focus-within a,#_nav_menu-505-165 .menu-item:hover a {
  background-color: rgba(238,238,238,0);
  padding-top: 0px;
  border-top-width: 0px;
  color: #ff7e00
}

#_nav_menu-505-165.oxy-nav-menu:not(.oxy-nav-menu-open) .sub-menu .menu-item a:hover {
  border: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #ff7e00
}

#modal-418-165 {
  display: none
}

@media (max-width: 479px) {
  #modal-418-165 {
      width:100%;
      display: flex;
      height: 100%;
      background-color: rgba(255,255,255,0)
  }
}

#span-478-165 {
  color: #000000
}

#-ouacfg_slider-409-165 .ouacfg-slider.ouacfg-navigation-outside {
  padding-bottom: 0px
}

body.oxygen-builder-body .oxy-ouacfg-slider {
  display: block;
  min-height: 50px;
  min-width: 50px
}

.ouacfg-slider-wrapper {
  position: relative;
  width: 100%
}

.ouacfg-slider {
  position: relative;
  text-align: center;
  overflow: hidden
}

.ouacfg-slider > .swiper-wrapper,.ouacfg-slider > .swiper-wrapper:not(.ct-section):not(.oxy-easy-posts),.ou-thumbnails-swiper > .swiper-wrapper,.ou-thumbnails-swiper > .swiper-wrapper:not(.ct-section):not(.oxy-easy-posts) {
  display: flex!important;
  flex-direction: inherit!important;
  align-items: inherit!important;
  justify-content: inherit!important;
  grid-column-gap: unset!important;
  grid-row-gap: unset!important;
  grid-auto-rows: auto!important
}

.ouacfg-slider-content {
  position: relative;
  text-align: center;
  overflow: hidden
}

.oxy-ouacfg-slider .swiper-slide {
  line-height: 0;
  padding-top: 0!important;
  padding-bottom: 0!important
}

.ouacfg-slider .ouacfg-slider-image-container {
  background: no-repeat 50%;
  background-size: cover;
  position: relative;
  display: inline-block;
  width: 100%
}

.ouacfg-slider .ougsld-img {
  height: 250px
}

.swiper-container-multirow .swiper-wrapper.auto-height-yes {
  height: 100%!important
}

.ouacfg-slider .swiper-pagination-bullets {
  cursor: default
}

.ouacfg-slider .swiper-pagination-bullets .swiper-pagination-bullet-active {
  opacity: 1!important
}

.ouacfg-slider.ouacfg-slider-navigation-outside .swiper-pagination {
  bottom: 0
}

.ouacfg-slider .swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer
}

.ouacfg-slider.swiper-container-horizontal>.swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
  background: rgba(0,0,0,.25);
  position: absolute
}

.ouacfg-slider .swiper-pagination-progress .swiper-pagination-progressbar {
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top
}

.ouacfg-slider-wrapper .ou-swiper-button {
  background-image: none;
  width: auto;
  height: auto;
  line-height: 1;
  position: absolute;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  z-index: 18;
  cursor: pointer;
  font-size: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  outline: 0
}

.ouacfg-slider-wrapper .ou-swiper-button:focus,.ouacfg-slider-wrapper .ou-swiper-button:active {
  outline: 0
}

.ouacfg-slider-wrapper .ou-swiper-button-prev {
  left: 10px
}

.ouacfg-slider-wrapper .ou-swiper-button-next {
  right: 10px
}

.ou-thumbnails-swiper .ouacfg-slider-thumb {
  background: no-repeat center;
  background-size: cover;
  height: 100%;
  position: relative;
  cursor: pointer;
  transition: transform 0.5s ease-out;
  --thumb-initial-scale: 1;
  --thumb-hover-scale: 1
}

.ou-thumbs-ratio-11 .ouacfg-slider-thumb {
  padding-bottom: 100%
}

.ou-thumbs-ratio-43 .ouacfg-slider-thumb {
  padding-bottom: 75%
}

.ou-thumbs-ratio-169 .ouacfg-slider-thumb {
  padding-bottom: 56.35%
}

.ou-thumbs-ratio-219 .ouacfg-slider-thumb {
  padding-bottom: 42.8571%
}

.oxy-ouacfg-slider .ou-swiper-button svg {
  width: 20px;
  height: 20px
}

.oxy-ouacfg-slider .ou-swiper-button svg,.oxy-ouacfg-slider .ou-swiper-button:hover svg {
  fill: currentColor
}

.ouacfg-caption-btn {
  background-color: #333;
  color: #fff;
  clear: both;
  display: inline-block;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out
}

.swiper-scale-effect .swiper-slide-cover,.swiper-scale-effect .swiper-slide-cover img {
  transition: transform 4.5s ease-out;
  transform: scale(1.18)
}

.swiper-scale-effect .swiper-slide.swiper-slide-active .swiper-slide-cover,.swiper-scale-effect .swiper-slide.swiper-slide-active .swiper-slide-cover img {
  transform: scale(1)
}

.ouacfg-slider img {
  max-width: 100%;
  height: auto
}

.ouacfg-slider.ouacfg-navigation-outside {
  padding-bottom: 40px
}

body.ougsld-off-scroll {
  -webkit-overflow-scrolling: touch
}

body.mfp-zoom-out-cur.ougsld-off-scroll {
  overflow: hidden;
  -webkit-overflow-scrolling: auto
}

.ougsld-close.mfp-close svg {
  width: 25px;
  height: 25px;
  color: #efefef;
  fill: currentColor
}

.ougsld-close.mfp-close:hover svg {
  color: #fff
}

#-ouacfg_slider-409-165 {
  oug-images: 191,192,194,193
}

#-ouacfg_slider-416-165 .ouacfg-slider.ouacfg-navigation-outside {
  padding-bottom: 0px
}

#-ouacfg_slider-416-165 {
  oug-images: 195,196,197,198;
  display: none
}

@media (max-width: 479px) {
  #-ouacfg_slider-409-165 {
      display:none
  }
}

@media (max-width: 479px) {
  #-ouacfg_slider-416-165 {
      display:flex
  }
}

#-ou_classic_acrd-446-165 .ou-accordion-button {
  background-color: rgba(255,255,255,0);
  border_radius_border-radius: 8px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 8px
}

#-ou_classic_acrd-446-165 .ou-accordion-label {
  letter-spacing: 0px
}

#-ou_classic_acrd-446-165 .ouacrd-icon-wrap {
  --ouacrd-icon-rotate: 90deg;
  width: 15px;
  height: 15px
}

#-ou_classic_acrd-446-165 .ouacrd-icon-wrap svg {
  color: #009900
}

#-ou_classic_acrd-446-165 .ouacrd-icon-left {
  margin-right: 15px
}

#-ou_classic_acrd-446-165 .ou-accordion svg {
  width: 35px;
  height: 35px
}

#-ou_classic_acrd-446-165 .ou-accordion-content {
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px
}

#-ou_classic_acrd-446-165 .toggle-content {
  display: block
}

#-ou_classic_acrd-446-165 .toggle-expand-icon:not(.same-toggle-icon) {
  display: none
}

#-ou_classic_acrd-446-165 .toggle-collapse-icon {
  display: flex
}

#-ou_classic_acrd-446-165 .ou-accordion-item-active .ouacrd-icon-wrap.same-toggle-icon svg {
  -webkit-transform: rotate(var(--ouacrd-icon-rotate));
  -moz-transform: rotate(var(--ouacrd-icon-rotate));
  transform: rotate(var(--ouacrd-icon-rotate))
}

#-ou_classic_acrd-446-165 .toggle-content {
  display: block
}

#-ou_classic_acrd-446-165 .toggle-expand-icon:not(.same-toggle-icon) {
  display: none
}

#-ou_classic_acrd-446-165 .toggle-collapse-icon {
  display: flex
}

#-ou_classic_acrd-446-165 .toggle-content {
  display: block
}

#-ou_classic_acrd-446-165 .toggle-expand-icon:not(.same-toggle-icon) {
  display: none
}

#-ou_classic_acrd-446-165 .toggle-collapse-icon {
  display: flex
}

.oxy-ou-classic-acrd,body.oxygen-builder-body .ou-accordion-content {
  min-height: 40px;
  width: 100%
}

.oxy-ou-classic-acrd {
  display: inline-flex
}

.ou-accordion,.ou-accordion-item {
  clear: both;
  width: 100%
}

.ou-accordion-title-wrap {
  flex-grow: 1
}

.ou-accordion-item {
  margin-bottom: 8px
}

.ou-accordion .ou-accordion-button {
  background-color: #efefef;
  cursor: pointer;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 12px 18px;
  transition: all 0.5s
}

.oxy-ou-classic-acrd .ou-accordion-label {
  clear: both;
  display: block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: .1em;
  font-family: inherit;
  text-transform: uppercase;
  width: 100%
}

.oxy-ou-classic-acrd .ou-accordion-subtitle {
  color: #999;
  font-size: 14px;
  text-transform: uppercase;
  clear: both;
  display: block;
  width: 100%
}

.oxy-ou-classic-acrd .ou-accordion-content,body.oxygen-builder-body .oxy-ou-classic-acrd .demo-text {
  clear: both;
  margin-bottom: 12px;
  padding: 18px 18px 10px;
  overflow: hidden
}

body:not(.oxygen-builder-body) .oxy-ou-classic-acrd .ou-accordion-content {
  display: none
}

.oxy-ou-classic-acrd .ouacrd-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  width: 20px
}

.oxy-ou-classic-acrd .ouacrd-icon-wrap svg {
  width: 15px;
  height: 15px;
  fill: currentColor;
  transition: transform 0.75s;
  -webkit-transition: -webkit-transform 0.75s
}

.oxy-ou-classic-acrd .ou-accordion-item-active .ouacrd-icon-wrap.same-toggle-icon svg {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  transform: rotateX(180deg)
}

.oxy-ou-classic-acrd .ouacrd-icon-left {
  margin-right: 15px
}

.oxy-ou-classic-acrd .ouacrd-icon-right {
  margin-left: 15px
}

.ou-accordion-button:hover .ouacrd-icon-wrap svg {
  fill: currentColor
}

body:not(.oxygen-builder-body) .toggle-icon:not(.same-toggle-icon) {
  display: none
}

#-ou_classic_acrd-446-165 {
  border-radius: 8px;
  z-index: 2;
  oxy-ou-classic-acrd-ouacrd-title: บริการของเรา
}

@media (max-width: 1120px) {
  #-ou_classic_acrd-446-165 .ouacrd-icon-left {
      margin-right:12px
  }

  #-ou_classic_acrd-446-165 .ou-accordion svg {
      width: 30px;
      height: 30px
  }
}

@media (max-width: 991px) {
  #-ou_classic_acrd-446-165 .ou-accordion svg {
      width:25px;
      height: 25px
  }
}

@media (max-width: 479px) {
  #-ou_classic_acrd-446-165 .ou-accordion-button {
      padding-left:4%;
      padding-top: 0%;
      padding-bottom: 0%;
      padding-right: 4%
  }

  #-ou_classic_acrd-446-165 .ou-accordion-item {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px
  }

  #-ou_classic_acrd-446-165 .ou-accordion-label {
      font-size: 16px;
      font-weight: 400;
      color: #2f2f2f
  }

  #-ou_classic_acrd-446-165 .ouacrd-icon-wrap {
      --ouacrd-icon-rotate: 180deg
  }

  #-ou_classic_acrd-446-165 .ouacrd-icon-wrap svg {
      color: #2f2f2f
  }

  #-ou_classic_acrd-446-165 .ouacrd-icon-left {
      margin-right: 10px
  }

  #-ou_classic_acrd-446-165 .ou-accordion-label:hover {
      color: #ff7e00
  }

  #-ou_classic_acrd-446-165 .ouacrd-icon-wrap svg:hover {
      color: #ff7e00
  }

  #-ou_classic_acrd-446-165 {
      border-radius: 0px;
      padding-left: 0px;
      padding-right: 0px;
      background-color: rgba(0,0,0,0);
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      padding-bottom: 0px;
      padding-top: 3%
  }
}

#-ou_animated_heading-341-165 .cd-headline.clip .cd-words-wrapper::after {
  background-color: #a3a3a3
}

#-ou_animated_heading-341-165 .baft-text {
  color: #000000
}

#-ou_animated_heading-341-165 .cd-words-wrapper * {
  color: #fe7e00
}

.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left
}

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0
}

.cd-words-wrapper b.is-visible {
  position: relative
}

.cd-words-wrapper b {
  opacity: 0
}

.cd-words-wrapper b.is-visible {
  opacity: 1
}

.cd-words-wrapper b,.cd-words-wrapper i,.cd-words-wrapper em {
  font-style: inherit;
  font-weight: inherit
}

.cd-headline.rotate-1 .cd-words-wrapper {
  -webkit-perspective: 300px;
  -moz-perspective: 300px;
  perspective: 300px
}

.cd-headline.rotate-1 b {
  opacity: 0;
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  transform: rotateX(180deg)
}

.cd-headline.rotate-1 b.is-visible {
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-animation: cd-rotate-1-in 1.2s;
  -moz-animation: cd-rotate-1-in 1.2s;
  animation: cd-rotate-1-in 1.2s
}

.cd-headline.rotate-1 b.is-hidden {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-animation: cd-rotate-1-out 1.2s;
  -moz-animation: cd-rotate-1-out 1.2s;
  animation: cd-rotate-1-out 1.2s
}

@-webkit-keyframes cd-rotate-1-in {
  0% {
      -webkit-transform: rotateX(180deg);
      opacity: 0
  }

  35% {
      -webkit-transform: rotateX(120deg);
      opacity: 0
  }

  65% {
      opacity: 0
  }

  100% {
      -webkit-transform: rotateX(360deg);
      opacity: 1
  }
}

@-moz-keyframes cd-rotate-1-in {
  0% {
      -moz-transform: rotateX(180deg);
      opacity: 0
  }

  35% {
      -moz-transform: rotateX(120deg);
      opacity: 0
  }

  65% {
      opacity: 0
  }

  100% {
      -moz-transform: rotateX(360deg);
      opacity: 1
  }
}

@keyframes cd-rotate-1-in {
  0% {
      -webkit-transform: rotateX(180deg);
      -moz-transform: rotateX(180deg);
      -ms-transform: rotateX(180deg);
      -o-transform: rotateX(180deg);
      transform: rotateX(180deg);
      opacity: 0
  }

  35% {
      -webkit-transform: rotateX(120deg);
      -moz-transform: rotateX(120deg);
      -ms-transform: rotateX(120deg);
      -o-transform: rotateX(120deg);
      transform: rotateX(120deg);
      opacity: 0
  }

  65% {
      opacity: 0
  }

  100% {
      -webkit-transform: rotateX(360deg);
      -moz-transform: rotateX(360deg);
      -ms-transform: rotateX(360deg);
      -o-transform: rotateX(360deg);
      transform: rotateX(360deg);
      opacity: 1
  }
}

@-webkit-keyframes cd-rotate-1-out {
  0% {
      -webkit-transform: rotateX(0deg);
      opacity: 1
  }

  35% {
      -webkit-transform: rotateX(-40deg);
      opacity: 1
  }

  65% {
      opacity: 0
  }

  100% {
      -webkit-transform: rotateX(180deg);
      opacity: 0
  }
}

@-moz-keyframes cd-rotate-1-out {
  0% {
      -moz-transform: rotateX(0deg);
      opacity: 1
  }

  35% {
      -moz-transform: rotateX(-40deg);
      opacity: 1
  }

  65% {
      opacity: 0
  }

  100% {
      -moz-transform: rotateX(180deg);
      opacity: 0
  }
}

@keyframes cd-rotate-1-out {
  0% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
      opacity: 1
  }

  35% {
      -webkit-transform: rotateX(-40deg);
      -moz-transform: rotateX(-40deg);
      -ms-transform: rotateX(-40deg);
      -o-transform: rotateX(-40deg);
      transform: rotateX(-40deg);
      opacity: 1
  }

  65% {
      opacity: 0
  }

  100% {
      -webkit-transform: rotateX(180deg);
      -moz-transform: rotateX(180deg);
      -ms-transform: rotateX(180deg);
      -o-transform: rotateX(180deg);
      transform: rotateX(180deg);
      opacity: 0
  }
}

.cd-headline.type .cd-words-wrapper {
  vertical-align: top;
  overflow: hidden
}

.cd-headline.type .cd-words-wrapper::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 90%;
  width: 1px;
  background-color: #aebcb9
}

.cd-headline.type .cd-words-wrapper.waiting::after {
  -webkit-animation: cd-pulse 1s infinite;
  -moz-animation: cd-pulse 1s infinite;
  animation: cd-pulse 1s infinite
}

.cd-headline.type .cd-words-wrapper.selected {
  background-color: transparent
}

.cd-headline.type .cd-words-wrapper.selected::after {
  visibility: hidden
}

.cd-headline.type b {
  visibility: hidden
}

.cd-headline.type b.is-visible {
  visibility: visible
}

.cd-headline.type i {
  position: absolute;
  visibility: hidden
}

.cd-headline.type i.in {
  position: relative;
  visibility: visible
}

@-webkit-keyframes cd-pulse {
  0% {
      -webkit-transform: translateY(-50%) scale(1);
      opacity: 1
  }

  40% {
      -webkit-transform: translateY(-50%) scale(0.9);
      opacity: 0
  }

  100% {
      -webkit-transform: translateY(-50%) scale(0);
      opacity: 0
  }
}

@-moz-keyframes cd-pulse {
  0% {
      -moz-transform: translateY(-50%) scale(1);
      opacity: 1
  }

  40% {
      -moz-transform: translateY(-50%) scale(0.9);
      opacity: 0
  }

  100% {
      -moz-transform: translateY(-50%) scale(0);
      opacity: 0
  }
}

@keyframes cd-pulse {
  0% {
      -webkit-transform: translateY(-50%) scale(1);
      -moz-transform: translateY(-50%) scale(1);
      -ms-transform: translateY(-50%) scale(1);
      -o-transform: translateY(-50%) scale(1);
      transform: translateY(-50%) scale(1);
      opacity: 1
  }

  40% {
      -webkit-transform: translateY(-50%) scale(0.9);
      -moz-transform: translateY(-50%) scale(0.9);
      -ms-transform: translateY(-50%) scale(0.9);
      -o-transform: translateY(-50%) scale(0.9);
      transform: translateY(-50%) scale(0.9);
      opacity: 0
  }

  100% {
      -webkit-transform: translateY(-50%) scale(0);
      -moz-transform: translateY(-50%) scale(0);
      -ms-transform: translateY(-50%) scale(0);
      -o-transform: translateY(-50%) scale(0);
      transform: translateY(-50%) scale(0);
      opacity: 0
  }
}

.cd-headline.rotate-2 .cd-words-wrapper {
  -webkit-perspective: 300px;
  -moz-perspective: 300px;
  perspective: 300px
}

.cd-headline.rotate-2 i,.cd-headline.rotate-2 em {
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.cd-headline.rotate-2 b {
  opacity: 0
}

.cd-headline.rotate-2 i {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-20px) rotateX(90deg);
  -moz-transform: translateZ(-20px) rotateX(90deg);
  -ms-transform: translateZ(-20px) rotateX(90deg);
  -o-transform: translateZ(-20px) rotateX(90deg);
  transform: translateZ(-20px) rotateX(90deg);
  opacity: 0
}

.is-visible .cd-headline.rotate-2 i {
  opacity: 1
}

.cd-headline.rotate-2 i.in {
  -webkit-animation: cd-rotate-2-in 0.4s forwards;
  -moz-animation: cd-rotate-2-in 0.4s forwards;
  animation: cd-rotate-2-in 0.4s forwards
}

.cd-headline.rotate-2 i.out {
  -webkit-animation: cd-rotate-2-out 0.4s forwards;
  -moz-animation: cd-rotate-2-out 0.4s forwards;
  animation: cd-rotate-2-out 0.4s forwards
}

.cd-headline.rotate-2 em {
  -webkit-transform: translateZ(20px);
  -moz-transform: translateZ(20px);
  -ms-transform: translateZ(20px);
  -o-transform: translateZ(20px);
  transform: translateZ(20px)
}

.no-csstransitions .cd-headline.rotate-2 i {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 0
}

.no-csstransitions .cd-headline.rotate-2 i em {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1)
}

.no-csstransitions .cd-headline.rotate-2 .is-visible i {
  opacity: 1
}

@-webkit-keyframes cd-rotate-2-in {
  0% {
      opacity: 0;
      -webkit-transform: translateZ(-20px) rotateX(90deg)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateZ(-20px) rotateX(-10deg)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateZ(-20px) rotateX(0deg)
  }
}

@-moz-keyframes cd-rotate-2-in {
  0% {
      opacity: 0;
      -moz-transform: translateZ(-20px) rotateX(90deg)
  }

  60% {
      opacity: 1;
      -moz-transform: translateZ(-20px) rotateX(-10deg)
  }

  100% {
      opacity: 1;
      -moz-transform: translateZ(-20px) rotateX(0deg)
  }
}

@keyframes cd-rotate-2-in {
  0% {
      opacity: 0;
      -webkit-transform: translateZ(-20px) rotateX(90deg);
      -moz-transform: translateZ(-20px) rotateX(90deg);
      -ms-transform: translateZ(-20px) rotateX(90deg);
      -o-transform: translateZ(-20px) rotateX(90deg);
      transform: translateZ(-20px) rotateX(90deg)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateZ(-20px) rotateX(-10deg);
      -moz-transform: translateZ(-20px) rotateX(-10deg);
      -ms-transform: translateZ(-20px) rotateX(-10deg);
      -o-transform: translateZ(-20px) rotateX(-10deg);
      transform: translateZ(-20px) rotateX(-10deg)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateZ(-20px) rotateX(0deg);
      -moz-transform: translateZ(-20px) rotateX(0deg);
      -ms-transform: translateZ(-20px) rotateX(0deg);
      -o-transform: translateZ(-20px) rotateX(0deg);
      transform: translateZ(-20px) rotateX(0deg)
  }
}

@-webkit-keyframes cd-rotate-2-out {
  0% {
      opacity: 1;
      -webkit-transform: translateZ(-20px) rotateX(0)
  }

  60% {
      opacity: 0;
      -webkit-transform: translateZ(-20px) rotateX(-100deg)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateZ(-20px) rotateX(-90deg)
  }
}

@-moz-keyframes cd-rotate-2-out {
  0% {
      opacity: 1;
      -moz-transform: translateZ(-20px) rotateX(0)
  }

  60% {
      opacity: 0;
      -moz-transform: translateZ(-20px) rotateX(-100deg)
  }

  100% {
      opacity: 0;
      -moz-transform: translateZ(-20px) rotateX(-90deg)
  }
}

@keyframes cd-rotate-2-out {
  0% {
      opacity: 1;
      -webkit-transform: translateZ(-20px) rotateX(0);
      -moz-transform: translateZ(-20px) rotateX(0);
      -ms-transform: translateZ(-20px) rotateX(0);
      -o-transform: translateZ(-20px) rotateX(0);
      transform: translateZ(-20px) rotateX(0)
  }

  60% {
      opacity: 0;
      -webkit-transform: translateZ(-20px) rotateX(-100deg);
      -moz-transform: translateZ(-20px) rotateX(-100deg);
      -ms-transform: translateZ(-20px) rotateX(-100deg);
      -o-transform: translateZ(-20px) rotateX(-100deg);
      transform: translateZ(-20px) rotateX(-100deg)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateZ(-20px) rotateX(-90deg);
      -moz-transform: translateZ(-20px) rotateX(-90deg);
      -ms-transform: translateZ(-20px) rotateX(-90deg);
      -o-transform: translateZ(-20px) rotateX(-90deg);
      transform: translateZ(-20px) rotateX(-90deg)
  }
}

.cd-headline.loading-bar span {
  display: inline-block;
  padding: .2em 0
}

.cd-headline.loading-bar .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top
}

.cd-headline.loading-bar .cd-words-wrapper::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 0;
  background: #0096a7;
  z-index: 2;
  -webkit-transition: width 0.3s -0.1s;
  -moz-transition: width 0.3s -0.1s;
  transition: width 0.3s -0.1s
}

.cd-headline.loading-bar .cd-words-wrapper.is-loading::after {
  width: 100%;
  -webkit-transition: width 3s;
  -moz-transition: width 3s;
  transition: width 3s
}

.cd-headline.loading-bar b {
  top: .2em;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s
}

.cd-headline.loading-bar b.is-visible {
  opacity: 1;
  top: 0
}

.cd-headline.slide span {
  display: inline-block;
  padding: .2em 0
}

.cd-headline.slide .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top
}

.cd-headline.slide b {
  opacity: 0;
  top: .2em
}

.cd-headline.slide b.is-visible {
  top: 0;
  opacity: 1;
  -webkit-animation: slide-in 0.6s;
  -moz-animation: slide-in 0.6s;
  animation: slide-in 0.6s
}

.cd-headline.slide b.is-hidden {
  -webkit-animation: slide-out 0.6s;
  -moz-animation: slide-out 0.6s;
  animation: slide-out 0.6s
}

@-webkit-keyframes slide-in {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-100%)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateY(20%)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0)
  }
}

@-moz-keyframes slide-in {
  0% {
      opacity: 0;
      -moz-transform: translateY(-100%)
  }

  60% {
      opacity: 1;
      -moz-transform: translateY(20%)
  }

  100% {
      opacity: 1;
      -moz-transform: translateY(0)
  }
}

@keyframes slide-in {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-100%);
      -moz-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      -o-transform: translateY(-100%);
      transform: translateY(-100%)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateY(20%);
      -moz-transform: translateY(20%);
      -ms-transform: translateY(20%);
      -o-transform: translateY(20%);
      transform: translateY(20%)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0)
  }
}

@-webkit-keyframes slide-out {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0)
  }

  60% {
      opacity: 0;
      -webkit-transform: translateY(120%)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(100%)
  }
}

@-moz-keyframes slide-out {
  0% {
      opacity: 1;
      -moz-transform: translateY(0)
  }

  60% {
      opacity: 0;
      -moz-transform: translateY(120%)
  }

  100% {
      opacity: 0;
      -moz-transform: translateY(100%)
  }
}

@keyframes slide-out {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0)
  }

  60% {
      opacity: 0;
      -webkit-transform: translateY(120%);
      -moz-transform: translateY(120%);
      -ms-transform: translateY(120%);
      -o-transform: translateY(120%);
      transform: translateY(120%)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      -o-transform: translateY(100%);
      transform: translateY(100%)
  }
}

.cd-headline.clip span {
  display: inline-block;
  padding: .2em 0
}

.cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top
}

.cd-headline.clip .cd-words-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: #aebcb9
}

.cd-headline.clip b {
  opacity: 0
}

.cd-headline.clip b.is-visible {
  opacity: 1
}

.cd-headline.zoom .cd-words-wrapper {
  -webkit-perspective: 300px;
  -moz-perspective: 300px;
  perspective: 300px
}

.cd-headline.zoom b {
  opacity: 0
}

.cd-headline.zoom b.is-visible {
  opacity: 1;
  -webkit-animation: zoom-in 0.8s;
  -moz-animation: zoom-in 0.8s;
  animation: zoom-in 0.8s
}

.cd-headline.zoom b.is-hidden {
  -webkit-animation: zoom-out 0.8s;
  -moz-animation: zoom-out 0.8s;
  animation: zoom-out 0.8s
}

@-webkit-keyframes zoom-in {
  0% {
      opacity: 0;
      -webkit-transform: translateZ(100px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateZ(0)
  }
}

@-moz-keyframes zoom-in {
  0% {
      opacity: 0;
      -moz-transform: translateZ(100px)
  }

  100% {
      opacity: 1;
      -moz-transform: translateZ(0)
  }
}

@keyframes zoom-in {
  0% {
      opacity: 0;
      -webkit-transform: translateZ(100px);
      -moz-transform: translateZ(100px);
      -ms-transform: translateZ(100px);
      -o-transform: translateZ(100px);
      transform: translateZ(100px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0)
  }
}

@-webkit-keyframes zoom-out {
  0% {
      opacity: 1;
      -webkit-transform: translateZ(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateZ(-100px)
  }
}

@-moz-keyframes zoom-out {
  0% {
      opacity: 1;
      -moz-transform: translateZ(0)
  }

  100% {
      opacity: 0;
      -moz-transform: translateZ(-100px)
  }
}

@keyframes zoom-out {
  0% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateZ(-100px);
      -moz-transform: translateZ(-100px);
      -ms-transform: translateZ(-100px);
      -o-transform: translateZ(-100px);
      transform: translateZ(-100px)
  }
}

.cd-headline.rotate-3 .cd-words-wrapper {
  -webkit-perspective: 300px;
  -moz-perspective: 300px;
  perspective: 300px
}

.cd-headline.rotate-3 b {
  opacity: 0
}

.cd-headline.rotate-3 i {
  display: inline-block;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.is-visible .cd-headline.rotate-3 i {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg)
}

.cd-headline.rotate-3 i.in {
  -webkit-animation: cd-rotate-3-in 0.6s forwards;
  -moz-animation: cd-rotate-3-in 0.6s forwards;
  animation: cd-rotate-3-in 0.6s forwards
}

.cd-headline.rotate-3 i.out {
  -webkit-animation: cd-rotate-3-out 0.6s forwards;
  -moz-animation: cd-rotate-3-out 0.6s forwards;
  animation: cd-rotate-3-out 0.6s forwards
}

.no-csstransitions .cd-headline.rotate-3 i {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 0
}

.no-csstransitions .cd-headline.rotate-3 .is-visible i {
  opacity: 1
}

@-webkit-keyframes cd-rotate-3-in {
  0% {
      -webkit-transform: rotateY(180deg)
  }

  100% {
      -webkit-transform: rotateY(0deg)
  }
}

@-moz-keyframes cd-rotate-3-in {
  0% {
      -moz-transform: rotateY(180deg)
  }

  100% {
      -moz-transform: rotateY(0deg)
  }
}

@keyframes cd-rotate-3-in {
  0% {
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      -o-transform: rotateY(180deg);
      transform: rotateY(180deg)
  }

  100% {
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      transform: rotateY(0deg)
  }
}

@-webkit-keyframes cd-rotate-3-out {
  0% {
      -webkit-transform: rotateY(0)
  }

  100% {
      -webkit-transform: rotateY(-180deg)
  }
}

@-moz-keyframes cd-rotate-3-out {
  0% {
      -moz-transform: rotateY(0)
  }

  100% {
      -moz-transform: rotateY(-180deg)
  }
}

@keyframes cd-rotate-3-out {
  0% {
      -webkit-transform: rotateY(0);
      -moz-transform: rotateY(0);
      -ms-transform: rotateY(0);
      -o-transform: rotateY(0);
      transform: rotateY(0)
  }

  100% {
      -webkit-transform: rotateY(-180deg);
      -moz-transform: rotateY(-180deg);
      -ms-transform: rotateY(-180deg);
      -o-transform: rotateY(-180deg);
      transform: rotateY(-180deg)
  }
}

.cd-headline.scale b {
  opacity: 0
}

.cd-headline.scale i {
  display: inline-block;
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0)
}

.is-visible .cd-headline.scale i {
  opacity: 1
}

.cd-headline.scale i.in {
  -webkit-animation: scale-up 0.6s forwards;
  -moz-animation: scale-up 0.6s forwards;
  animation: scale-up 0.6s forwards
}

.cd-headline.scale i.out {
  -webkit-animation: scale-down 0.6s forwards;
  -moz-animation: scale-down 0.6s forwards;
  animation: scale-down 0.6s forwards
}

.no-csstransitions .cd-headline.scale i {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 0
}

.no-csstransitions .cd-headline.scale .is-visible i {
  opacity: 1
}

@-webkit-keyframes scale-up {
  0% {
      -webkit-transform: scale(0);
      opacity: 0
  }

  60% {
      -webkit-transform: scale(1.2);
      opacity: 1
  }

  100% {
      -webkit-transform: scale(1);
      opacity: 1
  }
}

@-moz-keyframes scale-up {
  0% {
      -moz-transform: scale(0);
      opacity: 0
  }

  60% {
      -moz-transform: scale(1.2);
      opacity: 1
  }

  100% {
      -moz-transform: scale(1);
      opacity: 1
  }
}

@keyframes scale-up {
  0% {
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
      opacity: 0
  }

  60% {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
      opacity: 1
  }

  100% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
      opacity: 1
  }
}

@-webkit-keyframes scale-down {
  0% {
      -webkit-transform: scale(1);
      opacity: 1
  }

  60% {
      -webkit-transform: scale(0);
      opacity: 0
  }
}

@-moz-keyframes scale-down {
  0% {
      -moz-transform: scale(1);
      opacity: 1
  }

  60% {
      -moz-transform: scale(0);
      opacity: 0
  }
}

@keyframes scale-down {
  0% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
      opacity: 1
  }

  60% {
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
      opacity: 0
  }
}

.cd-headline.push b {
  opacity: 0
}

.cd-headline.push b.is-visible {
  opacity: 1;
  -webkit-animation: push-in 0.6s;
  -moz-animation: push-in 0.6s;
  animation: push-in 0.6s
}

.cd-headline.push b.is-hidden {
  -webkit-animation: push-out 0.6s;
  -moz-animation: push-out 0.6s;
  animation: push-out 0.6s
}

@-webkit-keyframes push-in {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-100%)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateX(10%)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0)
  }
}

@-moz-keyframes push-in {
  0% {
      opacity: 0;
      -moz-transform: translateX(-100%)
  }

  60% {
      opacity: 1;
      -moz-transform: translateX(10%)
  }

  100% {
      opacity: 1;
      -moz-transform: translateX(0)
  }
}

@keyframes push-in {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateX(10%);
      -moz-transform: translateX(10%);
      -ms-transform: translateX(10%);
      -o-transform: translateX(10%);
      transform: translateX(10%)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0)
  }
}

@-webkit-keyframes push-out {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0)
  }

  60% {
      opacity: 0;
      -webkit-transform: translateX(110%)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(100%)
  }
}

@-moz-keyframes push-out {
  0% {
      opacity: 1;
      -moz-transform: translateX(0)
  }

  60% {
      opacity: 0;
      -moz-transform: translateX(110%)
  }

  100% {
      opacity: 0;
      -moz-transform: translateX(100%)
  }
}

@keyframes push-out {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0)
  }

  60% {
      opacity: 0;
      -webkit-transform: translateX(110%);
      -moz-transform: translateX(110%);
      -ms-transform: translateX(110%);
      -o-transform: translateX(110%);
      transform: translateX(110%)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -ms-transform: translateX(100%);
      -o-transform: translateX(100%);
      transform: translateX(100%)
  }
}

a.anim-text-linka.anim-text-link:hover {
  text-decoration: none
}

#-ou_animated_heading-341-165 {
  font-size: clamp(1rem,calc(1rem + ((1vw - 0.3rem) * 1.6667)),2.5rem) ;
  font-weight: 500;
  line-height: 1
}

@media (max-width: 1120px) {
  #-ou_animated_heading-341-165 {
      font-size:clamp(0.875rem,calc(0.875rem + ((1vw - 0.3rem) * 0.6944)),1.5rem)
  }
}

@media (max-width: 479px) {
  #-ou_animated_heading-341-165 {
      font-size:clamp(1.25rem,calc(1.25rem + ((1vw - 0.3rem) * 0.5556)),1.75rem)
  }
}
